import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import { CREATE_PROPERTY } from "../../graphql/property";
import { useMutation } from "@apollo/client";
import AWS from "aws-sdk";

AWS.config.update({
  credentials: {
    accessKeyId: `${process.env.REACT_APP_AWS_S3_ACCESS_KEY}`,
    secretAccessKey: `${process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY}`,
  },
  region: `${process.env.REACT_APP_AWS_S3_REGION}`,
});

const s3 = new AWS.S3();

//import "./AdminCreatePropertyForm.css";
function AdminCreatePropertyForm() {
  const [createPropertyMutation] = useMutation(CREATE_PROPERTY);
  const navigate = useNavigate();

  const [address, setAddress] = useState("1420 E Madison St");
  const [state, setState] = useState("Jalisco");
  const [zipCode, setZipCode] = useState("98122");
  const [bedrooms, setBedrooms] = useState(2);
  const [bathrooms, setBathrooms] = useState(1);
  const [area, setArea] = useState(200);
  const [description, setDescription] = useState(
    "Departamento amueblado cerca del la universidad de guadalajara"
  );
  const [images, setImages] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
  };

  const uploadImageToS3 = async (file) => {
    const params = {
      Bucket: "promapa",
      Key: `properties/images/${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.log("Error uploading image");
      throw error;
    }
  };

  const createPropertyAction = async (e) => {
    e.preventDefault();
    try {
      const imageURLS = await Promise.all(images.map((image) => uploadImageToS3(image)));
      const { data } = await createPropertyMutation({
        variables: {
          address,
          state,
          zipCode,
          bedrooms,
          bathrooms,
          area,
          description,
          images: imageURLS
        },
      });
      if (data.createProperty) {
        navigate(`/property-details/${data.createProperty}`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="admin-create-property-form-container">
      <h1 className="admin-create-property-form-title">
        Register Real Estate Property
      </h1>
      <form
        className="admin-create-property-form-inputs-container"
        onSubmit={createPropertyAction}
      >
        <div className="admin-create-property-form input">
          <label htmlFor="propertyType">Property Type</label>
          <select id="propertyType" name="propertyType">
            <option value="">Select property type</option>
            <option value="house">House</option>
            <option value="apartment">Apartment</option>
          </select>
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="address">Address</label>
          <input type="text" id="address" name="address" />
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="state">State</label>
          <input type="text" id="state" name="state" />
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="zipCode">Zip Code</label>
          <input type="text" id="zipCode" name="zipCode" />
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="price">Price (MXN)</label>
          <input type="number" id="price" name="price" min="0" step="100" />
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="bedrooms">Bedrooms</label>
          <input type="number" id="bedrooms" name="bedrooms" min="0" />
        </div>
        <div className="admin-create-property-form input">
          <label htmlFor="bathrooms">Bathrooms</label>
          <input
            type="number"
            id="bathrooms"
            name="bathrooms"
            min="0"
            step="0.5"
          />
        </div>

        <div className="admin-create-property-form input">
          <label htmlFor="squareFeet">Square Feet</label>
          <input type="number" id="squareFeet" name="squareFeet" min="0" />
        </div>

        <div className="admin-create-property-form input">
          <label htmlFor="description">Description</label>
          <textarea id="description" name="description" rows={4}></textarea>
        </div>

        <div>
          <label htmlFor="images">Imagenes de la propiedad</label>
          <input
            type="file"
            id="images"
            accept="image/*"
            multiple
            onChange={handleImageChange}
          />
        </div>
        <div className="admin-create-property-form">
          <button type="submit" className="submit-btn">
            Register Property
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdminCreatePropertyForm;
