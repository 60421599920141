import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ExplorerLocationPredictionsList from "./ExplorerLocationPredictionsList";

import "./ExplorerSearchBar.css";
function ExplorerSearchBar() {

  const [location, setLocation] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [listingType, setListingType] = useState("")

  const [predictions, setPredictions] = useState([]);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const searchProperties = async (e) => {
    var params = Object.fromEntries(searchParams.entries());
    e.preventDefault();
    var searchBarParameters = {};
    if (location) {
      searchBarParameters.city = location;
    }
    if (minPrice) {
      searchBarParameters.minPrice = minPrice;
    }
    if (maxPrice) {
      searchBarParameters.maxPrice = maxPrice;
    }
    var newParameters = {
      ...params,
      ...searchBarParameters,
    };

    // Remove the city, this helps in cases where city is not accurate according to user input, just look for location
    // when location is selected from prections list
    if (newParameters.city) {
      delete newParameters.city;
    }
    if (!maxPrice) {
      delete newParameters.maxPrice;
    }
    if (!minPrice) {
      delete newParameters.minPrice;
    }
    const query = new URLSearchParams(newParameters).toString();
    navigate(`/map?${query}`);
  };

  useEffect(() => {
    var params = Object.fromEntries(searchParams.entries());
    if (params?.city) {
      setLocation(params.city);
    }
    if (params?.isForRent && JSON.parse(params.isForRent)!= null) {
      setListingType(JSON.parse(params.isForRent) ? "rent" : "buy")
    }
    if (params?.maxPrice) {
      setMaxPrice(parseInt(params.maxPrice, 10));
    }
    if (params?.minPrice) {
      setMinPrice(parseInt(params.minPrice, 10));
    }
  }, []);

  const inputLocationRef = useRef(null);

  const getPlaceDetails = async (placeId) => {
    return new Promise((resolve, reject) => {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placesService.getDetails(
        { placeId: placeId },
        (placeDetails, placeStatus) => {
          if (
            placeStatus === window.google.maps.places.PlacesServiceStatus.OK
          ) {
            const lat = placeDetails.geometry.location.lat();
            const lng = placeDetails.geometry.location.lng();
            resolve([lng, lat]); // Resolve the promise with the coordinates
          } else {
            reject("Failed to fetch place details"); // Reject if there's an error
          }
        }
      );
    });
  };

  // Event listener for selecting a prediction
  const handleLocationPredictionSelect = async (prediction) => {
    const locationSelected = prediction?.place_id
      ? await getPlaceDetails(prediction.place_id)
      : null;

    setLocation(prediction.displayText);
    setPredictions([]);

    var params = Object.fromEntries(searchParams.entries());
    var newParameters = {
      ...params,
      center: JSON.stringify(locationSelected),
      city: prediction?.city,
      area: JSON.stringify([]),
    };

    const query = new URLSearchParams(newParameters).toString();
    navigate(`/map?${query}`);
  };

  useEffect(() => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    const fetchPredictions = (query) => {
      if (!query || query == "") {
        setPredictions([]);
        return;
      }

      autocompleteService.getPlacePredictions(
        {
          input: query,
          types: ["(cities)"],
          componentRestrictions: { country: "mx" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const filteredPredictions = predictions.map((prediction) => {
              const city = prediction.terms[0].value;
              const state = prediction.terms[1]?.value;
              return {
                place_id: prediction.place_id,
                city,
                state,
                displayText: `${city}, ${state || ""}`.trim(),
              };
            });
            setPredictions(filteredPredictions);
          } else {
            setPredictions([]);
          }
        }
      );
    };

    const handleInputChange = (e) => {
      if (e.target.value) {
        const query = e.target.value;
        fetchPredictions(query);
      }
    };

    const inputEl = inputLocationRef.current;
    inputEl.addEventListener("input", handleInputChange);

    return () => {
      inputEl.removeEventListener("input", handleInputChange);
    };
  }, []);

  return (
    <div className="explorer-search-bar-container">
      <form
        className="explorer-search-bar-search-form-container"
        onSubmit={searchProperties}
      >
        <div className="explorer-search-bar-location-input-container">
          <input
            ref={inputLocationRef}
            type="text"
            placeholder="Enter location"
            className="explorer-search-bar-search-input location-input"
            autoComplete="off"
            value={location}
            onChange={(e) => setLocation(e.target.value ? e.target.value : "")}
          />

          {/* Render filtered predictions */}
          {predictions.length > 0 && (
            <ExplorerLocationPredictionsList
              predictions={predictions}
              handleLocationPredictionSelect={handleLocationPredictionSelect}
              setPredictions={setPredictions}
            />
          )}
        </div>

        <select className="explorer-search-bar-search-input type-select">
          <option value="">Tipo de propiedad</option>
          <option value="apartment">Departamento</option>
          <option value="house">Casa</option>
          <option value="condo">Local comercial</option>
          <option value="townhouse">Espacio de recreación</option>
        </select>
        <input
          type="text"
          placeholder="Precio Mínimo"
          className="explorer-search-bar-search-input price-input"
          value={minPrice}
          onChange={(e) =>
            setMinPrice(e.target.value ? Number(e.target.value) : "")
          }
        />
        <input
          type="text"
          placeholder="Precio Máximo"
          className="explorer-search-bar-search-input price-input"
          value={maxPrice}
          onChange={(e) =>
            setMaxPrice(e.target.value ? Number(e.target.value) : "")
          }
          onFocus={() => console.log("Selected")}
        />
        <select className="explorer-search-bar-search-input looking-for-select" value={listingType} onChange={(e) => setListingType(e.target.value)}>
          <option value="">Cualquiera</option>
          <option value="rent">Renta</option>
          <option value="buy">Compra</option>
        </select>
        <button
          type="submit"
          className="explorer-search-bar-search-button"
          aria-label="Search"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="explorer-search-bar-search-icon"
          >
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        </button>
      </form>
    </div>
  );
}

export default ExplorerSearchBar;
