import React, { useEffect, useState } from "react";
import InitialOptionCard from "./InitialOptionCard";

import "./InitialOptionsContainer.css";
import imageInitialOptionBuy from "../../../images/initial-option-buy.jpg";
import imageInitialOptionRent from "../../../images/initial-option-rent.jpg";
import imageInitialOptionLoan from "../../../images/initial-option-loan.jpg";
import imageInitialOptionAgent from "../../../images/initial-option-agent.jpg";
import imageInitialOptionZones from "../../../images/initial-option-zones.jpg";

function InitialOptionsContainer() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [animateEffect, setAnimateEffect] = useState(false);
  const [cardsInformationIndex, setCardsInformationIndex] = useState(null);
  const cardsInformation = [
    "Al momento de adquirir una casa, es clave considerar aspectos como la ubicación, asegurándote de que esté en una zona con acceso a escuelas, supermercados, áreas verdes y transporte público. También es importante evaluar el diseño y distribución de los espacios, buscando que se adapten a tus necesidades y estilo de vida. Además, fíjate en las amenidades disponibles, como jardines, terrazas, áreas comunes o estacionamiento, ya que agregan valor y comodidad a tu inversión. Por último, piensa a futuro: una propiedad en una zona en desarrollo puede aumentar su valor con el tiempo.",
    "Al buscar una casa en renta, prioriza la ubicación, asegurándote de que esté cerca de los servicios y lugares que necesitas, como transporte público, supermercados o tu lugar de trabajo. Revisa las amenidades incluidas, como estacionamiento, áreas verdes o electrodomésticos, ya que pueden hacer la experiencia más cómoda. Finalmente, asegúrate de que el precio esté en línea con el mercado local y con los beneficios que la propiedad ofrece.",
    "Evalúa la seguridad del área revisando índices delictivos o simplemente caminando por la zona para observar el ambiente. También analiza el nivel de ruido y el tránsito, especialmente si prefieres un lugar tranquilo. Si tienes un estilo de vida activo, busca zonas con parques, áreas recreativas o gimnasios cercanos. Además, ten en cuenta el crecimiento y desarrollo del área, ya que vivir en una comunidad en expansión puede aumentar el valor de tu inversión a futuro.",
    "Al contactar a un agente de bienes raíces, es importante verificar su experiencia y conocimiento del mercado en la zona que te interesa, ya que esto garantiza un mejor asesoramiento. Sé claro sobre tus necesidades, como presupuesto, tamaño de la propiedad y características deseadas, para que el agente pueda ofrecerte opciones relevantes.",
    "Al momento de buscar un préstamo para vivienda, empieza por evaluar tu capacidad financiera y calcular cuánto puedes destinar mensualmente al pago sin comprometer tu estabilidad económica. Investiga las tasas de interés ofrecidas por diferentes instituciones financieras y compara sus condiciones, como plazos, comisiones y seguros asociados. Es clave revisar tu historial crediticio, ya que un buen puntaje puede ayudarte a obtener mejores condiciones.",
  ];

  const cards = [
    <InitialOptionCard
      text="COMPRAR"
      url={imageInitialOptionBuy}
      key={0}
      setCardsInformationIndex={setCardsInformationIndex}
      index={cardsInformationIndex}
      cardIndex={0}
      setAnimateEffect={setAnimateEffect}
    />,
    <InitialOptionCard
      text="RENTAR"
      url={imageInitialOptionRent}
      key={1}
      setCardsInformationIndex={setCardsInformationIndex}
      index={cardsInformationIndex}
      cardIndex={1}
      setAnimateEffect={setAnimateEffect}
    />,
    <InitialOptionCard
      text="MEJORES ZONAS "
      url={imageInitialOptionZones}
      key={2}
      setCardsInformationIndex={setCardsInformationIndex}
      index={cardsInformationIndex}
      cardIndex={2}
      setAnimateEffect={setAnimateEffect}
    />,
    <InitialOptionCard
      text="CONTACTA A UN AGENTE"
      url={imageInitialOptionAgent}
      key={3}
      setCardsInformationIndex={setCardsInformationIndex}
      index={cardsInformationIndex}
      cardIndex={3}
      setAnimateEffect={setAnimateEffect}
    />,
    <InitialOptionCard
      text="OBTENER UN PRESTAMO"
      url={imageInitialOptionLoan}
      key={4}
      setCardsInformationIndex={setCardsInformationIndex}
      index={cardsInformationIndex}
      cardIndex={4}
      setAnimateEffect={setAnimateEffect}
    />,
  ];

  const handleCarouselAction = (num) => {
    setCurrentIndex((currentIndex + num) % 5);
    if(cardsInformationIndex!=null){
      setCardsInformationIndex((currentIndex + num) % 5)
    }
  };

  useEffect(() => {
    const handleScreenResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleScreenResize);
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  });

  useEffect(() => {
    if (animateEffect) {
      const timer = setTimeout(() => setAnimateEffect(false), 500);
      return () => clearTimeout(timer);
    }
  }, [animateEffect])

  return (
    <div className="home-initial-options-container">
      <div className="home-initial-options-container-title-container">
        <p>La mejor forma para encontrar tu nuevo hogar</p>
      </div>

      {screenWidth > 510 ? (
        <div className="home-intial-options-cards-container">
          {cards.map((card) => card)}
        </div>
      ) : (
        <div className="home-initial-options-cards-carousel-container">
          <div
            className="home-initial-options-cards-carousel-container-button"
            onClick={() => handleCarouselAction(4)}
          >
            {"<"}
          </div>
          {cards[currentIndex]}
          <div
            className="home-initial-options-cards-carousel-container-button"
            onClick={() => handleCarouselAction(1)}
          >
            {">"}
          </div>
        </div>
      )}
      {cardsInformationIndex != null ? (
        <div className="home-initial-options-card-information-container">
          <p className={animateEffect ? 'initial-options-text-animate-effect' : null}>{cardsInformation[cardsInformationIndex]}</p>
        </div>
      ) : null}
    </div>
  );
}

export default InitialOptionsContainer;
