import React from "react";

import "./Footer.css"

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-logo-container">
                <p>ProMapa</p>
            </div>
            <div className="footer-information-container">
                <p>
                    Terminos y condiciones de uso
                </p>
                <p>
                    Politica de privacidad
                </p>
                <p>
                    ProMapa ©2024. Derechos reservados.
                </p>
            </div>
            <div className="footer-social-container">
                <p>Siguenos en nuestras redes sociales</p>
            </div>
        </div>
    )
}

export default Footer;