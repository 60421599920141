import React, { useState } from "react";
import InitialOptionsContainer from "../components/common/Home/InitialOptionsContainer";
import HomeSearchHeader from "../components/common/Home/HomeSearchHeader";
import { GET_ALL_PROPERTIES } from "../graphql/property";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import HomePropertyCard from "../components/common/Home/HomePropertyCard";
import Footer from "../components/common/Footer";

import "./Home.css";

function Home() {
  const [properties, setProperties] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_PROPERTIES);

  useEffect(() => {
    if (!loading && data?.getAllProperties) {
      setProperties(data.getAllProperties);
    }
  }, [loading]);
  return (
    <div>
      <HomeSearchHeader />
      <InitialOptionsContainer />
      <div className="home-neary-you-section-container">
        <div className="home-neary-you-header-section-container">
          <p className="home-near-you-header-section-title">Cerca de ti</p>
          <Link to={"/map"}>
            <p className="home-near-you-header-section-see-all">Explorar</p>
          </Link>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="home-near-you-properties-section-container">
            {properties.map((property) => (
              <li key={property._id} className="home-near-you-properties-section-list-item-container">
                <Link to={`/property-details/${property._id}`}>
                  <HomePropertyCard property={property} />
                </Link>
              </li>
            ))}
          </div>
        )}
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
