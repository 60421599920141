import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./Navbar.css";
function Navbar() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const goProfile = () => {
    navigate("/profile");
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isMenuOpen]);

  return (
    <nav className="container-nav">
      <Link to="/" className="container-logo-nav">
        <h4>ProMapa</h4>
      </Link>

      <div className="container-options-nav">
        <Link
          to="/"
          className={`${
            location.pathname === "/"
              ? "navbar-option-selected"
              : "navbar-option"
          }`}
        >
          Inicio
        </Link>
        <Link
          to="/map"
          className={`${
            location.pathname === "/map"
              ? "navbar-option-selected"
              : "navbar-option"
          }`}
        >
          Mapa
        </Link>
        {user ? (
          <Link
            to="/profile"
            className={`navbar-option-rounded ${
              location.pathname === "/profile"
                ? "navbar-option-rounded-selected"
                : "navbar-option"
            }`}>
            Perfil
          </Link>
        ) : (
          <Link
            to="/login"
            className={`navbar-option-rounded ${
              location.pathname === "/login"
                ? "navbar-option-rounded-selected"
                : "navbar-option"
            }`}
          >
            Login
          </Link>
        )}
        {user ? null : (
          <Link
            to="/register"
            className={`${
              location.pathname === "/register"
                ? "navbar-option-selected"
                : "navbar-option"
            }`}
          >
            Registarse
          </Link>
        )}
      </div>

      <div
        className="navbar-minimized-menu-container"
        onClick={() => setIsMenuOpen(true)}
      >
        <span>---</span>
      </div>
      {isMenuOpen ? (
        <div className="navbar-minimized-menu-options-container">
          <div className="navbar-minimized-menu-option-container close-button">
            <span onClick={() => setIsMenuOpen(false)}>X</span>
          </div>
          <Link to={"/"} onClick={() => setIsMenuOpen(false)}>
            <div className="navbar-minimized-menu-option-container">
              <p>Inicio</p>
            </div>
          </Link>
          <Link to={'/map'} onClick={() => setIsMenuOpen(false)}>
            <div className="navbar-minimized-menu-option-container">
              <p>Mapa</p>
            </div>
          </Link>
          <Link to={'/login'} onClick={() => setIsMenuOpen(false)}>
            <div className="navbar-minimized-menu-option-container">
              <p>Login</p>
            </div>
          </Link>
          <Link to={'/register'} onClick={() => setIsMenuOpen(false)}>
            <div className="navbar-minimized-menu-option-container">
              <p>Register</p>
            </div>
          </Link>
          <Link to={'/profile'} onClick={() => setIsMenuOpen(false)}>
            <div className="navbar-minimized-menu-option-container">
              <p>Perfil</p>
            </div>
          </Link>
        </div>
      ) : null}
    </nav>
  );
}

export default Navbar;
