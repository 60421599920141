import React from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./InitialOptionCard.css";

function InitialOptionCard(props) {
  const { text, url, setCardsInformationIndex, cardIndex, index, setAnimateEffect } = props;

  const handleCardSelection = () => {
    if (index == cardIndex) {
      setCardsInformationIndex(null);
    } else {
      setCardsInformationIndex(cardIndex);
      setAnimateEffect(true)
    }
  };

  const cardStyle = {
    backgroundImage: `url(${url})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className="home-initial-option-card" style={cardStyle} onClick={() => handleCardSelection()}>
      <div className="home-initial-option-card-text-container">
        <p>{text}</p>
      </div>
    </div>
  );
}

export default InitialOptionCard;
