import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_ROLES } from "../graphql/user";

import "./Register.css";
function Register() {
  const { register, user } = useContext(AuthContext);
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const { loading, error, data } = useQuery(GET_ALL_ROLES);
  const [verificationSent, setVerificationSent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
    if (data?.getAllRoles) {
      setRoles(data.getAllRoles);
    }
  }, [user, loading]);

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const result = await register(name, email, password, role);
      setVerificationSent(result);
    } catch (error) {
      console.log(error);
    }
  };
  return loading ? (
    <p>loading....</p>
  ) : (
    <div className="register-container">
      {!verificationSent ? (
        <div className="register-form-container">
          <form className="register-form" onSubmit={handleRegister}>
            <h2 className="register-title">Registro</h2>
            <div className="register-form-group">
              <label htmlFor="register-name" className="register-label">
                Nombre
              </label>
              <input
                type="text"
                id="register-name"
                className="register-input"
                placeholder="Ingresa tu nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="register-lastname" className="register-label">
                Apellido
              </label>
              <input
                type="text"
                id="register-lastname"
                className="register-input"
                placeholder="Ingresa tu apellido"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="register-email" className="register-label">
                Correo
              </label>
              <input
                type="email"
                id="register-email"
                className="register-input"
                placeholder="Ingresa tu correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="register-password" className="register-label">
                Contraseña
              </label>
              <input
                type="password"
                id="register-password"
                className="register-input"
                placeholder="Ingresa tu contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="register-form-group">
              <label htmlFor="register-role" className="register-label">
                Rol
              </label>
              <select
                id="register-role"
                className="register-input"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
              >
                <option value="">Elige un tipo de usuario</option>
                {roles.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="register-button">
              Registrarse
            </button>
            <p className="register-footer">
              Ya tienes una cuenta?{" "}
              <a href="/login" className="register-link">
                Login
              </a>
            </p>
          </form>
        </div>
      ) : verificationSent?.register ? (
        <p>
          Enviamos un correo de confirmation a tu email, una vez verificado
          podras iniciar sesion desde la pagina de login
        </p>
      ) : (
        <p>Hubo un error, vuelve a intentarlo de nuevo</p>
      )}
    </div>
  );
}

export default Register;
