import { gql } from "@apollo/client";

export const CREATE_PROPERTY = gql`
  mutation createProperty($address: String!, $state: String!, $zipCode: String!, $bedrooms: Int!, $bathrooms: Int!, $area: Int!, $description: String!, $images: [String]) {
    createProperty(address: $address, state: $state, zipCode: $zipCode, bedrooms: $bedrooms, bathrooms: $bathrooms, area: $area, description: $description, images: $images)
  }
`;

export const LOAD_TEST_DATA = gql`
  mutation loadTestData {
    loadTestData
  }
`

export const GET_PROPERTIES_BY_OWNER = gql`
  query getPropertiesByOwner($owner_id: ID!){
    getPropertiesByOwner(owner_id: $owner_id) {
      _id
      city
      address
      status
      displayPriceMap
      displayPriceCard
      location {
        type
        coordinates
      }
    }
  }
`

export const GET_ALL_PROPERTIES = gql`
  query getAllProperties {
    getAllProperties {
      _id
      city
      displayPriceMap
      displayPriceCard
      location {
        type
        coordinates
      }
    }
  }
`
export const GET_PROPERTY_BY_ID = gql`
  query getPropertyById($id: ID!){
    getPropertyById(id: $id) {
      _id
      images
      displayPriceMap
      displayPriceCard
      location {
        type
        coordinates
      }
    }
  }
`

export const GET_PROPERTIES = gql`
  query getProperties($city: String, $neighborhood: String, $maxPrice: Int, $minPrice: Int, $area: [[Float]], $isForRent: Boolean){
    getProperties(city: $city, neighborhood: $neighborhood, maxPrice: $maxPrice, minPrice: $minPrice, area: $area, isForRent: $isForRent) {
      _id
      city
      displayPriceMap
      displayPriceCard
      location {
        type
        coordinates
      }
    }
  }
`