import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { useNavigate } from "react-router-dom";

import "./Login.css";
function Login() {
  const { login, user } = useContext(AuthContext);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/profile");
    }
  }, [user]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
    } catch {
      console.log("Credenciales invalidas");
    }
  };

  const handleGoogleLogin = () => {
    console.log("Login with Google clicked");
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <form className="login-form" onSubmit={handleLogin}>
          <h2 className="login-title">Inicia sesión</h2>
          <div className="login-form-group">
            <label htmlFor="login-email" className="login-label">
              Email
            </label>
            <input
              type="text"
              id="login-email"
              className="login-input"
              placeholder="Ingresa tu email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="login-form-group">
            <label htmlFor="login-password" className="login-label">
              Contraseña
            </label>
            <input
              type="password"
              id="login-password"
              className="login-input"
              placeholder="Ingresa tu contraseña"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          <button
            type="button"
            className="login-google-button"
            onClick={handleGoogleLogin}
          >
            Login with Google
          </button>
          <p className="login-footer">
            Aun no tienes una cuenta?{" "}
            <a href="/register" className="login-link">
              Sign up
            </a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
