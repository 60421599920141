import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";

import ExplorerLocationPredictionsList from "../Explorer/ExplorerLocationPredictionsList";

import "./HomeSearchBarHeader.css";

const libraries = ["places"];

function HomeSearchBarHeader() {
  const [location, setLocation] = useState("");
  const [city, setCity] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [center, setCenter] = useState(null);
  const [rentFilter, setRentFilter] = useState(true);
  const [predictions, setPredictions] = useState([]);

  const { isLoaded } = useJsApiLoader({
    id: process.env.REACT_APP_GOOGLE_MAP_ID,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
    libraries,
  });

  const navigate = useNavigate();
  const inputLocationRef = useRef(null);

  const searchProperties = async (e) => {
    e.preventDefault();
    var searchBarParameters = {};
    if (location) {
      searchBarParameters.city = city;
    }
    if (center) {
      searchBarParameters.center = JSON.stringify(center);
    }
    if (minPrice) {
      searchBarParameters.minPrice = minPrice;
    }
    if (maxPrice) {
      searchBarParameters.maxPrice = maxPrice;
    }
    searchBarParameters.isForRent = rentFilter

    const query = new URLSearchParams(searchBarParameters).toString();
    navigate(`/map?${query}`);
  };

  const getPlaceDetails = async (placeId) => {
    return new Promise((resolve, reject) => {
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
      placesService.getDetails(
        { placeId: placeId },
        (placeDetails, placeStatus) => {
          if (
            placeStatus === window.google.maps.places.PlacesServiceStatus.OK
          ) {
            const lat = placeDetails.geometry.location.lat();
            const lng = placeDetails.geometry.location.lng();
            resolve([lng, lat]); // Resolve the promise with the coordinates
          } else {
            reject("Failed to fetch place details"); // Reject if there's an error
          }
        }
      );
    });
  };

  // Event listener for selecting a prediction
  const handleLocationPredictionSelect = async (prediction) => {
    const locationSelected = prediction?.place_id
      ? await getPlaceDetails(prediction.place_id)
      : null;

    setLocation(prediction.displayText);
    setCenter(locationSelected);
    setCity(prediction.city);
    setPredictions([]);
  };

  useEffect(() => {
    if (isLoaded) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();

      const fetchPredictions = (query) => {
        if (!query || query == "") {
          setPredictions([]);
          return;
        }

        autocompleteService.getPlacePredictions(
          {
            input: query,
            types: ["(cities)"],
            componentRestrictions: { country: "mx" },
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const filteredPredictions = predictions.map((prediction) => {
                const city = prediction.terms[0].value;
                const state = prediction.terms[1]?.value;
                return {
                  place_id: prediction.place_id,
                  city,
                  state,
                  displayText: `${city}, ${state || ""}`.trim(),
                };
              });
              setPredictions(filteredPredictions);
            } else {
              setPredictions([]);
            }
          }
        );
      };

      const handleInputChange = (e) => {
        if (e.target.value) {
          const query = e.target.value;
          fetchPredictions(query);
        }
      };

      const inputEl = inputLocationRef.current;
      inputEl.addEventListener("input", handleInputChange);

      return () => {
        inputEl.removeEventListener("input", handleInputChange);
      };
    }
  }, [isLoaded]);

  return (
    <div className="home-search-bar-header-container">
      <div className="home-search-bar-header-find-bar-container">
        <div className="home-search-bar-header-rent-sell-container">
          <div
            className={`home-search-bar-header-rent-sell-text-container ${
              rentFilter ? "option-selected" : null
            }`}
            onClick={() => setRentFilter(true)}
          >
            <p>En renta</p>
          </div>
          <div
            className={`home-search-bar-header-rent-sell-text-container ${
              rentFilter ? null : "option-selected"
            }`}
            onClick={() => setRentFilter(false)}
          >
            <p>En venta</p>
          </div>
        </div>
        <div className="home-search-bar-header-bar-container">
          <form
            className="home-search-bar-header-search-form"
            onSubmit={searchProperties}
          >
            <div className="home-search-bar-header-location-input-container">
              {isLoaded ? (
                <input
                  ref={inputLocationRef}
                  type="text"
                  placeholder="Ingresa ubicación"
                  className="home-search-bar-header-search-input home-search-bar-header-location-input"
                  value={location}
                  onChange={(e) =>
                    setLocation(e.target.value ? e.target.value : "")
                  }
                />
              ) : null}

              {predictions.length > 0 ? (
                <ExplorerLocationPredictionsList
                  predictions={predictions}
                  handleLocationPredictionSelect={
                    handleLocationPredictionSelect
                  }
                  setPredictions={setPredictions}
                />
              ) : null}
            </div>
            <select className="home-search-bar-header-search-input home-search-bar-header-type-select">
              <option value="">Tipo de propiedad</option>
              <option value="apartment">Departamento</option>
              <option value="house">Casa</option>
              <option value="condo">Local comercial</option>
              <option value="townhouse">Espacio de recreación</option>
            </select>
            <input
              type="number"
              placeholder="Precio máximo"
              className="home-search-bar-header-search-input home-search-bar-header-price-input"
              onChange={(e) =>
                setMaxPrice(e.target.value ? Number(e.target.value) : null)
              }
            />
            <input
              type="number"
              placeholder="Precio mínimo"
              className="home-search-bar-header-search-input home-search-bar-header-price-input"
              onChange={(e) =>
                setMinPrice(e.target.value ? Number(e.target.value) : null)
              }
            />
            <button
              type="submit"
              className="home-search-bar-header-search-button"
              aria-label="Search"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="home-search-bar-header-search-icon"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HomeSearchBarHeader;
