import React, { useRef, useEffect } from "react";

import "./ExplorerLocationPredictionsList.css";
function ExplorerLocationPredictionsList(props) {
  const { predictions, handleLocationPredictionSelect, setPredictions } = props;
  const predictionsListRef = useRef(null);

  const handleClick = (event) => {
    if (
      predictionsListRef.current &&
      !predictionsListRef.current.contains(event.target)
    ) {
      setPredictions([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <div
      ref={predictionsListRef}
      className="explorer-location-predictions-list-container"
    >
      {predictions.map((prediction, index) => (
        <li
          key={index}
          onClick={() => handleLocationPredictionSelect(prediction)}
        >
          {prediction.displayText}
        </li>
      ))}
    </div>
  );
}

export default ExplorerLocationPredictionsList;
